'use client'

import { Calendar as CalendarIcon } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from '../../popover.tsx'
import { cn } from '~/utils/misc.tsx'
import { DatePicker } from '../../datePicker.tsx'
import { useState } from 'react'
import { dateToDataTable } from '~/utils/strings.ts'

export type DatePickerProps = {
  label?: string
  date?: Date
  onChange: (newDate?: Date) => void
  name?: string
  className?: string
  iconClassName?: string
}
export function DatePickerSelect({
  label = 'Escoge una fecha',
  date,
  onChange,
  className,
  iconClassName,
}: DatePickerProps) {
  const [isOpen, setOpen] = useState(false)

  const handleChangeDate = (value?: Date) => {
    setOpen(false)
    onChange(value)
  }

  return (
    <Popover onOpenChange={() => setOpen(!isOpen)} open={isOpen}>
      <PopoverTrigger asChild className="w-full">
        <fieldset
          className={cn(
            `relative flex h-11 w-full max-w-[300px] cursor-pointer items-center justify-start rounded-lg border border-input px-4 hover:border-secondary focus:border-2 ${
              isOpen && 'border-2 border-secondary'
            }`,
            className,
          )}
        >
          <legend
            className={`invisible h-[5px] px-1 text-xs ${
              date || isOpen ? 'block' : 'hidden'
            }`}
          >
            {label}
          </legend>
          <CalendarIcon
            className={cn('mr-2 h-4 w-4 text-secondary', iconClassName)}
          />
          <div
            className={`
absolute duration-200
            ${
              date || isOpen
                ? ` left-5 top-0 origin-top-left  -translate-y-2 scale-75 font-semibold
        text-secondary peer-focus:top-0 peer-focus:-translate-y-2 peer-focus:scale-75 peer-focus:text-secondary
`
                : `
        left-10 top-2.5 translate-y-0  scale-100
        font-normal text-foreground-muted
`
            }
          `}
          >
            {label}
          </div>
          <div className="z-40 mt-1">{date && dateToDataTable(date)}</div>
        </fieldset>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <DatePicker
          mode="single"
          selected={date}
          onSelect={handleChangeDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
