'use client'

import * as React from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { DayPicker as DayPickerRoot } from 'react-day-picker'
import { cn } from '~/utils/misc.tsx'
import { buttonVariants } from '~/components/ui/inputs/button/Button.tsx'
import { es } from 'date-fns/locale/index.js'

export type CalendarProps = React.ComponentProps<typeof DayPickerRoot>

function DatePicker({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPickerRoot
      locale={es}
      showOutsideDays={showOutsideDays}
      className={cn('w-fit p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'text-foreground-muted rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent-100 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100',
        ),
        day_selected:
          'bg-secondary text-white hover:bg-secondary-header hover:text-white focus:bg-secondary focus:text-white',
        day_today: 'bg-accent-100 text-accent',
        day_outside: 'text-foreground-muted opacity-50',
        day_disabled: 'text-foreground-muted opacity-50',
        day_range_middle:
          'aria-selected:bg-accent-100 aria-selected:text-accent',
        day_hidden: 'invisible',
        tbody: !showOutsideDays ? '[&>*:first-child]:justify-end' : '',
        ...classNames,
      }}
      modifiersClassNames={{ booked: 'bg-secondary opacity-50 text-white' }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
      }}
      {...props}
    />
  )
}
DatePicker.displayName = 'Calendar'

export { DatePicker }
